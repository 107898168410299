function staffSlide() {
    $(".staff-list.has-slider").each(function () {
        let _this = $(this);
        let swiperContainer = _this.find(".swiper-container");
        let btnPrev = _this.find(".swiper-prev");
        let btnNext = _this.find(".swiper-next");
        let staffSlider = new Swiper(swiperContainer, {
            slidesPerView: 4,
            spaceBetween: 30,
            navigation: {
                prevEl: btnPrev,
                nextEl: btnNext,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 15,
                },
                768: {
                    slidesPerView: 2.15,
                    spaceBetween: 15,
                },
            },
        });
    });
}

const partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 20,
    navigation: {
        prevEl: ".partner-slider .swiper-prev",
        nextEl: ".partner-slider .swiper-next",
    },
    loop: true,
    autoplay: {
        delay: 3000
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
    },
});

const customerSlider = new Swiper(".customer-slider .swiper-container", {
    slidesPerView: 5,
    slidesPerColumn: 2,
    spaceBetween: 20,
    loop: true,
    autoplay: {
        delay: 3000
    },
    navigation: {
        prevEl: ".customer-slider .swiper-prev",
        nextEl: ".customer-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            slidesPerColumn: 1,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 2.5,
            slidesPerColumn: 1,
            spaceBetween: 15,
        },
    },
});


const awardSlider = new Swiper(".award-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 30,
    navigation: {
        prevEl: ".award-slider .swiper-prev",
        nextEl: ".award-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
    },
});

const historySlider = new Swiper(".history-slider .swiper-container", {
    slidesPerView: 4,
    direction: 'vertical',
    slideToClickedSlide: true,
    navigation: {
        prevEl: ".history-slider .swiper-prev",
        nextEl: ".history-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            direction: 'horizontal',
            slidesPerView: 2.5,
            spaceBetween: 30,
        },
        768: {
            direction: 'horizontal',
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
    },
});

export default staffSlide;
