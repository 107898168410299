import headerInit from "./headerInit";
import homeInit from "./homeInit";
import projectSlide from "./projectSlideInit";
import projectDetailSlide from "./projectDetailInit";
import { fieldImageResize, fieldDetailSlider } from "./fieldInit";
import staffSlide from "./aboutInit";

jQuery(function () {
    var resizeTimer;

    headerInit();

    projectSlide();
    projectDetailSlide();

    staffSlide();

    $.fancybox.defaults.backFocus = false;

    $(".counter").counterUp({
        offset: $(".global-header").innerHeight(),
        delay: 20,
    });

    if ($(window).width() > 1024) {
        fieldImageResize();

        $(window).on("resize", function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                fieldImageResize();
            }, 250);
        });
    }

    $(".about-nav a").on("click", function (e) {
        e.preventDefault();
        let el = $(this).attr("href");
        scrollToElement(el);
    });

    $(".about-nav-wrap").sticky({
        topSpacing: $(".global-header").innerHeight(),
        zIndex: 50,
    });

    var aboutNav = document.querySelector(".about-nav");
    var ms = new MenuSpy(aboutNav, {
        threshold: $(".global-header").innerHeight(),
    });

    var isAnimating = false;
    $(".back-to-top").click(function () {
        if (isAnimating) {
            // do nothing if the button is already being animated
            return false;
        }

        // set the flag to indicate that the button is being animated
        isAnimating = true;

        // perform the scroll animation
        $("html, body").animate({ scrollTop: 0 }, 800, function () {
            // reset the flag when the animation is complete
            isAnimating = false;
        });

        return false;
    });

    try {
        var observerForm = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type === "childList") {
                    $(".wrap-form").each(function () {
                        let captchaContainer = $(this).find(".frm-captcha");
                        let submitContainer = $(this).find(".frm-btnwrap");
                        $(captchaContainer).prepend($(submitContainer));
                    });
                }
            });
        });

        observerForm.observe(
            document.querySelector(".form-container .ModuleContent > div"),
            {
                childList: true,
            }
        );

        $(".wrap-form").each(function () {
            let captchaContainer = $(this).find(".frm-captcha");
            let submitContainer = $(this).find(".frm-btnwrap");
            $(captchaContainer).prepend($(submitContainer));
        });
    } catch (error) {}
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed
    );
};
