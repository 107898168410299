const homeBannerSlider = new Swiper(".home-banner-slider .swiper-container", {
    autoplay: {
        delay: 4000
    },
    pagination: {
        el: '.home-banner-slider .swiper-pagination'
    }
})



export default homeBannerSlider