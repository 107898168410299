const projectDetailSlide = function () {
    var projectDetailThumbnail = new Swiper(
        ".project-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 30,
            observeParents: true,
            observer: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    spaceBetween: 15,
                    freeMode: true,
                    slidesPerView: 3.5,
                },
                768: {
                    spaceBetween: 15,
                    freeMode: true,
                    slidesPerView: 2.5,
                },
            },
        }
    );

    var projectDetailImages = new Swiper(
        ".project-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: projectDetailThumbnail,
            },
            navigation: {
                prevEl: ".project-detail-images .swiper-prev",
                nextEl: ".project-detail-images .swiper-next",
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.project-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.project-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );

    var projectDetailVideo = new Swiper(".project-detail-video .swiper-container",{
        centeredSlides: true,
        slidesPerView: 'auto',
        initialSlide: 1,
        navigation: {
            prevEl: ".project-detail-video .swiper-prev",
            nextEl: ".project-detail-video .swiper-next",
        },
    })
};



export { projectDetailSlide };

export default projectDetailSlide