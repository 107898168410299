function headerInit() {
    const menuMapping = new MappingListener({
        selector: ".menu-wrapper",
        mobileWrapper: ".mobile-menu-container",
        mobileMethod: "appendTo",
        desktopWrapper: ".header-right",
        desktopMethod: "prependTo",
        breakpoint: 1025,
    }).watch();

    $("header").sticky({
        topSpacing: 0,
        zIndex: 50,
    });

    $('.menu-toggle').on('click', function(){
        $('.mobile-menu').fadeIn()
        $('.mobile-menu').addClass('is-open')
    })
    
    $('.mobile-menu-overlay').on('click', function(){
        $('.mobile-menu').fadeOut()
        $('.mobile-menu').removeClass('is-open')
    })

    if ($(window).width() < 1025) {
        $(".has-sub .toggle").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("fa-chevron-down fa-chevron-up");
        });
    } else {
    }

}

export default headerInit