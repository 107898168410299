const fieldImageResize = function () {
    $(".field-item").each(function (index) {
        let _this = $(this);
        let img = _this.find(".field-img");
        let offset = _this.find(" > .container").offset().left;
        if (index % 2 === 0) {
            $(img).css({
                paddingLeft: offset + 15 + "px",
            });
        } else {
            $(img).css({
                paddingRight: offset + 15 + "px",
            });
        }
    });
};

const fieldDetailSlider = new Swiper(
    ".field-detail-feature-slider .swiper-container",
    {
        spaceBetween: 16,
        breakpoints: {
            1024: {
                slidesPerView: 1.25,
                centeredSlides: true
            }
        }
    }
);

export default fieldImageResize;
export { fieldImageResize, fieldDetailSlider };
